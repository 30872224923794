import { timeHeader } from '@/helpers/ag-grid/headerFormats'

export default [
    {
        headerName: 'ID',
        field: 'id',
    },
    {
        headerName: 'LEA',
        field: 'lea',
        initialHide: true,
        filter: false,
    },
    {
        headerName: 'School Year',
        field: 'schoolYear',
        iniitalHide: true,
        filter: false,
    },
    {
        headerName: 'School Code',
        field: 'schoolCode',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    {
        headerName: 'Course ID',
        field: 'courseId',
        filter: false,
    },
    {
        headerName: timeHeader('Update Date'),
        field: 'updateDate',
        filter: 'date',
    },
    {
        headerName: 'Is Deleted',
        field: 'isDeleted',
        filter: false,
    },
    {
        headerName: 'Absent Days Equivalent',
        field: 'absentDaysEquivalent',
        filter: false,
    },
    {
        headerName: 'Attendance Code Detail',
        field: 'attendanceCodeDetail',
        filter: false,
    },
    {
        headerName: 'Attendance Code',
        field: 'attendanceCode',
        filter: false,
    },
    {
        headerName: 'Attendance Code Original',
        field: 'attendanceCodeOriginal',
        filter: false,
    },
    {
        headerName: 'Attendance Date',
        field: 'attendanceDate',
        filter: false,
    },
    {
        headerName: 'Attendance Reason',
        field: 'attendanceReason',
        filter: false,
    },
    {
        headerName: 'Class Period',
        field: 'classPeriod',
        filter: false,
    },
    {
        headerName: 'Course Section ID',
        field: 'courseSectionId',
        filter: false,
    },
    {
        headerName: 'Section Number',
        field: 'sectionNumber',
        filter: false,
    },
    {
        headerName: 'Student ID',
        field: 'studentId',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    {
        headerName: 'Verified Indicator',
        field: 'verifiedIndicator',
        filter: false,
    },
]
